import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'
import { Sitemap, SiteMapYearListing } from '@jelly/templates'

const MONTHS_BY_NAME = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]

export default function Index({ data }) {
	const meta = {
		title: 'Sitemap: business.com',
		description: 'Find Archived Content on business.com',
		robots: 'index, follow',
		canonical: '/sitemap/',
	}
	const yearMonth = data.articlesAggregation.map((item) => item._id)
	const oldestYearMonth = yearMonth[yearMonth.length - 1].split('-')
	const currentDate = new Date()
	const sitemap = {}
	for (let year = Number(oldestYearMonth[0]); year <= currentDate.getFullYear(); year++) {
		sitemap[year] = {}
		for (let month = 1; month <= 12; month++) {
			const key = `${year}-${month < 10 ? `0${month}` : month}`
			sitemap[year][MONTHS_BY_NAME[month - 1]] = yearMonth.includes(key) ? key.replace('-', '/') : null
		}
	}
	return (
		<Sitemap meta={meta}>
			<SiteMapYearListing data={sitemap} />
		</Sitemap>
	)
}
Index.propTypes = { data: object, pageContext: object }

export const GatsbyQuery = graphql`
	query articlesAggregation($group: JSONObject) {
		articlesAggregation(pipeline: [{ group: $group }, { sort: { _id: -1 } }])
	}
`
